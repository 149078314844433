import * as React from 'react'
import Footer from './footer'
import 'bootstrap/dist/css/bootstrap.min.css'
import ToolHeader from './header-tool'

// Defines the basic layout of every page on the website.
// TODO: Change from flexbox div to Container?
const BaseLayoutTool = ({pageTitle, pageDescription, toolLink}) => {
  return (
    <div style={{minHeight:"100vh", height:"100%", display:"flex", flexDirection:"column"}}>
      <ToolHeader pageTitle={pageTitle} pageDescription={pageDescription} style={{flex:"0 0 auto"}}></ToolHeader>
      <div fluid style={{flex:"1 1 auto", height:"100%", display:"flex", flexDirection:"column"}}>
        <iframe title="tool-iframe" style={{height:"100vh", width:"100%"}} src={toolLink}/>
      </div>
      <Footer style={{flex:"0 1 auto"}}/>
    </div>
  )
}

export default BaseLayoutTool