import * as React from 'react'
import BaseLayoutTool from '../../components/base-layout-tool'

const AppendSpreadsheetsPage = () => {
    return (
        <BaseLayoutTool 
        pageTitle="Append Spreadsheets | App " 
        pageDescription="Adds one spreadsheet to the bottom of the other using this TNNL tool. It does not merge data if two columns have the same data. Works with csv and Excel files."
        toolLink="https://dfappend-ne2vtntboq-nw.a.run.app"
        />
    )
}

export default AppendSpreadsheetsPage